import React, { useState, useEffect, useCallback } from "react";
import { Container, Table } from "reactstrap";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Input,
  FormGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import UpdateProductButton from "views/buttons/updateProductButton";
import AddProductButton from "../../views/buttons/addProductButton";

import { getAllProducts, deleteProduct } from "../../network/ApiAxios";
import ProductPagination from "../../views/pagination/productPagination";
import { categoryList } from "constants/category";

const MainTableProduct = () => {
  const [products, setProducts] = useState([]);
  const [pageData, setPageData] = useState({});
  const [show, setShow] = useState(false);
  const [productId, setProductId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [sortValue, setSortValue] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [categoryValue, setCategoryValue] = useState("");

  const getAll = useCallback(
    async (
      page = 1,
      search = searchText,
      sort = sortValue,
      category = categoryValue
    ) => {
      const data = await getAllProducts({ category, page, search, sort });
      if (data && data.success && data.data) {
        setProducts(data.data);
        setPageData({
          pageCount: data.pageCount,
          currentPage: data.currentPage,
          nextPage: data.nextPage,
          allCount: data.allProductsCount,
        });
      }
    },
    [searchText, sortValue, categoryValue]
  );

  const sortProducts = () => {
    if (sortValue === "") {
      setSortValue(1);
    } else if (sortValue === 1) {
      setSortValue(-1);
    } else if (sortValue === -1) {
      setSortValue("");
    }
  };

  const clearAllFilters = () => {
    setSearchText("");
    setSortValue("");
    setCategoryValue("");
    getAll(1, "", "", "");
  };

  const handleModalShow = () => {
    setShow(true);
  };
  const handleToggle = () => {
    setShow(!show);
  };

  const deleteProductClick = async (id) => {
    handleToggle();
    const data = await deleteProduct(id);
    if (data && data.success && data.message) {
      toast.success("Mahsulot o'chirildi");
      getAll();
    }
  };

  const deleteClick = (id) => {
    setProductId(id);
    handleModalShow();
  };

  const onChangeSearch = (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
    getAll(1, e.target.value);
  };

  const onChangeCategory = (e) => {
    e.preventDefault();
    setCategoryValue(e.target.value);
    getAll(1, searchText, sortValue, e.target.value);
  };

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setBtnDisabled(false);
      }, 2500);
    } else {
      setBtnDisabled(true);
    }
  }, [show, setBtnDisabled]);

  useEffect(() => {
    getAll();
  }, [getAll]);

  return (
    <div className="mt-4">
      <Container>
        <Row>
          <Col>
            <Row>
              <Col>
                <AddProductButton getAll={getAll} />
              </Col>
              <Col md="3" className="text-right">
                <Button onClick={clearAllFilters}>Filterni tozalash</Button>
              </Col>
              <Col md="2" className="text-right">
                <Input
                  id="category"
                  type="select"
                  value={categoryValue}
                  onChange={onChangeCategory}
                >
                  <option value={""}>Barchasi</option>
                  {categoryList.map((item) => (
                    <option value={item.value} key={item.label}>
                      {item.label}
                    </option>
                  ))}
                </Input>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Input
                    placeholder="Qidiruv..."
                    value={searchText}
                    onChange={onChangeSearch}
                  />
                </FormGroup>
              </Col>
            </Row>
            {products.length > 0 ? (
              <Table className="mt-4">
                <thead>
                  <tr align="center">
                    <th className="align-middle">T/R</th>
                    <th className="text-left align-middle">Nomi</th>
                    <th className="text-left align-middle">Sotuv narxi</th>
                    <th className="text-left align-middle">
                      Chegirmadan <br /> so'ng narxi
                    </th>
                    <th className="text-left align-middle">
                      Y. muddati{" "}
                      <Button
                        className={`btn btn-sm ${
                          sortValue === 1
                            ? "btn-success"
                            : sortValue === -1
                            ? "btn-danger"
                            : ""
                        }`}
                        onClick={sortProducts}
                      >
                        A-z
                      </Button>
                    </th>
                    <th className="text-left align-middle">Qancha bor</th>
                    <th className="text-left align-middle">Bar Code</th>
                    <th>{""}</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((item, index) => (
                    <tr align="center" key={item._id}>
                      <td>{index + 1 + (pageData.currentPage - 1) * 10}</td>
                      <td align="left">{item.productName}</td>
                      <td>{item.sellingPrice}</td>
                      <td>{item.afterDiscountPrice || item.sellingPrice}</td>
                      <td>{item.expirationDate}</td>
                      <td>{item.amountProduct + " " + item.unitOfAmount}</td>
                      <td align="left">{item.barCode}</td>
                      <td>
                        <UpdateProductButton
                          getAll={getAll}
                          isUpdated={true}
                          product={item}
                        />
                        <Button
                          className="btn-sm btn-danger"
                          onClick={() => deleteClick(item._id)}
                        >
                          D
                        </Button>
                        <Link to={"/admin/product/" + item._id}>
                          <Button color="info" className="btn-sm">
                            I
                          </Button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <h1 className="mt-4">Mahsulot mavjud emas!</h1>
            )}
            {pageData.pageCount > 1 ? (
              <ProductPagination getAll={getAll} pageData={pageData} />
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Container>
      <Modal isOpen={show} toggle={handleToggle} centered={true}>
        <ModalBody className="mt-2">
          Haqiqatdan ham mahsulotni o'chirmoqchimisiz?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleToggle}>
            Bekor qilish
          </Button>
          <Button
            className="btn-danger"
            disabled={btnDisabled}
            onClick={() => deleteProductClick(productId)}
          >
            O'chirish
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default MainTableProduct;
